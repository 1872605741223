import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function SpinnerComponent() {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', height: '70%'}}> 
    <CircularProgress/>
    </div>
  )
}

export default SpinnerComponent