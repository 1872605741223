import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonGroup from "@mui/material/ButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import SendTestModal from "./SendTestModal";
import HoldRejectModal from "./HoldRejectModal"
import actions from "../../../redux/candidateDetail/actions";
import { CANDIDATE_STATUS, CANDIDATE_STATUS_TEXT } from "../../../constants/candidateStatus";
import NotInterestedModal from "./NotInterestedModal";

const ActionButton = ({candidateStatus , candidate}) => {
    const dispatch = useDispatch();
    const {
        candidate_id,
    } = useSelector((state) => state.candidateDetail.candidate);

    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [openBtn, setOpenBtn] = useState(false);
    const [openModalHold, setOpenModalHold] = useState(false);
    const [openModalNotInterested, setOpenModalNotInterested] = useState(false);
    const [openModalReject, setOpenModalReject] = useState(false);
    const [openModalTest, setOpenModalTest] = useState(false);

    const { loading, candidate: { candidate_id: currentCandidateId } } = useSelector(
        (state) => state.candidateDetail
    );

    //------------------Dropdown-Button------------------//
    const options =
    candidateStatus === CANDIDATE_STATUS.PENDING
            ? ["Send test email", "Put on hold", "Reject Candidate", "Not Interested"]
            : candidateStatus === CANDIDATE_STATUS.TEST_STAGE
                ? ["Send Tech interview email", "Put on hold", "Reject Candidate", "Not Interested"]
                : candidateStatus === CANDIDATE_STATUS.TECH_INTERVIEW_STAGE
                    ? [
                        "Send HR interview email",
                        "Put on hold",
                        "Reject Candidate",
                        "Not Interested"
                    ]
                    : candidateStatus === CANDIDATE_STATUS.HR_INTERVIEW_STAGE
                        ? ["Send offer", "Put on hold", "Reject Candidate", "Not Interested"]
                        : candidateStatus === CANDIDATE_STATUS.OFFER_SENT
                            ? ["Approve candidate", "Put on hold", "Reject Candidate", "Not Interested"]
                            : candidateStatus === CANDIDATE_STATUS.ON_HOLD
                                ? ["Send test email", "Reject Candidate", "Not Interested"]
                                : [];



    // const handleMenuItemClick = (event, index) => {
    //     console.log("this is menuItemClickkk :::: ", index)
    //     setSelectedIndex(index);
    //     setOpenBtn(false);
    // };
    const handleClickOpenAction = (action) => {
        action === "Send test email" ?
            setOpenModalTest(true) :
            action === "Put on hold" ?
                setOpenModalHold(true) :
                action === "Not Interested" ?
                    setOpenModalNotInterested(true) :
                    action === "Reject Candidate" ? setOpenModalReject(true) :
                        console.log("placeholder")
    };

    const handleMenuItemClick = (event, index) => {
        setOpenBtn(false);
        console.info(`You clicked ${options[index]}`);
        options[index] === "Send test email"
            ? handleClickOpenAction(options[index])
            : options[index] === "Put on hold"
                ? handleClickOpenAction(options[index])
                : options[index] === "Not Interested"
                    ? handleClickOpenAction(options[index])
                    : options[index] === "Send Tech interview email"
                        ? sendInterviewEmail(candidate?.candidate_id, "Tech")
                        : options[index] === "Send HR interview email"
                            ? sendInterviewEmail(candidate?.candidate_id, "HR")
                            : options[index] === "Reject Candidate"
                                ? handleClickOpenAction(options[index])
                                : options[index] === "Approve candidate"
                                    ? sendAcceptanceEmail(candidate?.candidate_id)
                                    : options[index] === "Send offer"
                                        ? handleUpdateStatus(candidate?.candidate_id, 4)
                                        : console.log("this is a PLACEHOLDER!");
    };

    const handleToggle = () => {
        setOpenBtn((prevOpen) => !prevOpen);
    };

    const handleCloseBtn = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenBtn(false);
    };
    const handleUpdateStatus = (id, status, comments) => {
        dispatch({
            type: actions.UPDATE_CANDIDATE_STATUS_CALL,
            payload: {
                status,
                candidate_id: id,
                comments,
                handleSuccess: () => {
                    dispatch({
                        type: actions.GET_CANDIDATE_DETAILS_CALL,
                        payload: { candidate_id },
                    });
                },
            },
        });
    };
    const sendInterviewEmail = (id, type) => {
        if (type === "Tech") {
            dispatch({
                type: actions.SEND_TECH_INTERVIEW_EMAIL_CALL,
                payload: {
                    candidate_id: id,
                    handleSuccess: () => {
                        dispatch({
                            type: actions.GET_CANDIDATE_DETAILS_CALL,
                            payload: { candidate_id },
                        });
                    },
                },
            });
        } else {
            dispatch({
                type: actions.SEND_HR_INTERVIEW_EMAIL_CALL,
                payload: {
                    candidate_id: id,
                    handleSuccess: () => {
                        dispatch({
                            type: actions.GET_CANDIDATE_DETAILS_CALL,
                            payload: { candidate_id },
                        });
                    },
                },
            });
        }
    };
    const sendAcceptanceEmail = (id) => {
        dispatch({
            type: actions.SEND_ACCEPTANCE_EMAIL_CALL,
            payload: {
                candidate_id: id,
                handleSuccess: () => {
                    dispatch({
                        type: actions.GET_CANDIDATE_DETAILS_CALL,
                        payload: { candidate_id },
                    });
                },
            },
        });

    };

    return (
        <>
            <ButtonGroup
                // sx={{ marginLeft: "auto", marginRight: "1rem" }}
                ref={anchorRef}
                aria-label="split button"
                size="small"
            >
                <LoadingButton
                    loading={candidate?.candidate_id === candidate_id && loading}
                    variant="outlined"
                    sx={{ cursor: 'auto' }} 
                >
                    {CANDIDATE_STATUS_TEXT[candidateStatus]}
                </LoadingButton>
                { options.length ? 
                <Button
                    size="small"
                    aria-controls={openBtn ? "split-button-menu" : undefined}
                    aria-expanded={openBtn ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button> : null}
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={openBtn}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseBtn}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) =>
                                                handleMenuItemClick(event, index)
                                            }
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <SendTestModal candidate={candidate} open={openModalTest} setOpen={setOpenModalTest} />
            <HoldRejectModal candidate={candidate} action={"Put on hold"} open={openModalHold} setOpen={setOpenModalHold} handleUpdateStatus={handleUpdateStatus} />
            <HoldRejectModal candidate={candidate} action={"Reject candidate"} open={openModalReject} setOpen={setOpenModalReject} handleUpdateStatus={handleUpdateStatus} />
            <NotInterestedModal candidate={candidate} open={openModalNotInterested} setOpen={setOpenModalNotInterested} handleUpdateStatus={handleUpdateStatus} />

        </>

    )
}

export default ActionButton