export const downloadFile = (data, title) => {
    // Create a Blob from the response data
    const blob = new Blob([data], { type: 'application/octet-stream' });
  
    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create a link element for download
    const link = document.createElement('a');
    link.href = url;
  
    // Set the download attribute for the link
    link.setAttribute(
      'download',
      title,
    );
  
    // Append the link to the document body and trigger a click event
    document.body.appendChild(link);
    link.click();
  
    // Clean up by revoking the Blob URL
    window.URL.revokeObjectURL(url);
  };
  