import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";

function HistoyAccordion() {
    const { history } = useSelector(
        (state) => state.candidateDetail.candidate
    );

    // tests accordion controls: ---------------
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const ACTIONS = {
        "0": "Created",
        "1": "Test stage",
        "2": "Tech Interview Stage",
        "3": "HR Interview Stage",
        "4": "Offer Sent",
        "5": "Approved",
        "6": "Put oh hold",
        "7": "Rejected",
        "8": "Not Interested"
    }
    return (
        <div>
            <Divider role="presentation" sx={{ width: "80vw" }} textAlign="left">
                <h2>Candidate History</h2>{" "}
            </Divider>
            {history?.map((action, index) => {
                return (
                    <div className="test-container">
                        <Accordion
                            sx={{ width: "700px" }}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                            >
                                <Typography sx={{
                                    color:
                                        action.action === "7"
                                            ? "#ff1744"
                                            : action.action === "5"
                                                ? "green"
                                                : "text.secondary", width: "33%", flexShrink: 0
                                }}>
                                    {ACTIONS[action.action]}
                                </Typography>
                                <Typography>
                                    {action.date_time.split("T")[0]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {action.user_id && (
                                        <p>
                                            Adminiatrateur: <span>{action.user_id}</span>
                                        </p>
                                    )}

                                    {action.comments ? (
                                        <p>
                                            Comment:{" "}
                                            <span>{action.comments}</span>
                                        </p>
                                    ) :
                                        <p>
                                            Comment:{" "}
                                            <span>No comments were provided.</span>
                                        </p>
                                    }

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                );
            })}
        </div>
    );
}

export default HistoyAccordion;
