const actions = {
  SET_STATE: "candidateDetail/SET_STATE",
  RESET_STATE: "candidateDetail/RESET_STATE",
  GET_CANDIDATE_DETAILS_CALL: "candidateDetail/GET_CANDIDATE_DETAILS_CALL",
  UPDATE_CANDIDATE_INFO: "candidateDetail/UPDATE_CANDIDATE",
  UPDATE_CANDIDATE_STATUS_CALL: "candidateDetail/UPDATE_CANDIDATE_STATUS_CALL",
  UPDATE_CANDIDATE_VISIBLE_CALL: "candidateDetail/UPDATE_CANDIDATE_VISIBLE_CALL",
  DELETE_CANDIDATE_CALL: "candidateDetail/DELETE_CANDIDATE_CALL",
  GET_TESTS_CALL: "candidateDetail/GET_TESTS_CALL",
  GET_CANDIDATE_TESTS_CALL: "candidateDetail/GET_CANDIDATE_TESTS_CALL",
  SEND_TEST_CALL: "candidateDetail/SEND_TEST_CALL",
  SEND_TECH_INTERVIEW_EMAIL_CALL:
    "candidateDetail/SEND_TECH_INTERVIEW_EMAIL_CALL",
  SEND_HR_INTERVIEW_EMAIL_CALL: "candidateDetail/SEND_HR_INTERVIEW_EMAIL_CALL",
  SEND_ACCEPTANCE_EMAIL_CALL: "candidateDetail/SEND_ACCEPTANCE_EMAIL_CALL",
  EVALUATE_TEST_CALL: "candidateDetail/EVALUATE_TEST_CALL",
  CANCEL_TEST_CALL: "candidateDetail/CANCEL_TEST_CALL",
};

export default actions;
