import React from "react";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/candidateDetail/actions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

function TestForm({
  handleCloseModal, id, candidate
}) {
  const [test, setTest] = React.useState("");
  const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
const formattedTomorrow = tomorrow.toISOString().split("T")[0];
  const [endDate, setEndDate] = React.useState(formattedTomorrow);
  const dateNow = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const { tests } = useSelector((state) => state.candidateDetail);
  // const { candidate_id } = useSelector((state) => state.candidateDetail.candidate);

  useEffect(() => {
    dispatch({ type: actions.GET_TESTS_CALL });
  }, []);

  const handleChange = (event) => {
    setTest(event.target.value);
  };

  const handleDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCloseModal();
    dispatch({
      type: actions.SEND_TEST_CALL,
      payload: {
        candidate: candidate,
        candidate_id: id,
        test_title: test,
        deadline: endDate,
        handleSuccess: () => {
          dispatch({
            type: actions.GET_CANDIDATE_DETAILS_CALL,
            payload: { id },
          });
          dispatch({
            type: actions.GET_CANDIDATE_TESTS_CALL,
            payload: { id },
          });
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        fullWidth
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          marginTop: "2rem",
        }}
      >
        <InputLabel id="test_select">Tests</InputLabel>
        <Select
          required
          labelId="test_select"
          id="test_select"
          error={!test.length}
          value={test}
          label="Test"
          onChange={handleChange}
        >
          {tests.map((test) => (
            <MenuItem key={test.title} value={test.title}>
              {test.title}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="end_date"
          label="End date"
          type="date"
          value={endDate}
          error={endDate < dateNow || endDate === dateNow}
          helperText={
            endDate < dateNow || endDate === dateNow
              ? "Please enter a valid date"
              : ""
          }
          required
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          <Button color="error" variant="text" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            disabled={endDate < dateNow || endDate === dateNow || !test.length}
            variant="text"
            type="submit"
          >
            Send Test
          </Button>
        </Box>
      </FormControl>
    </form>
  );
}

export default TestForm;
