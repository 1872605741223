import { isExpired } from "react-jwt";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import actions from "../redux/auth/actions";

const token = localStorage.getItem('token');


export const login = (token, user) => {
  if (window !== "undefined") {
    localStorage.setItem("token", token);

    localStorage.setItem("user", JSON.stringify({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      roles: user.roles,
    }));
  }
};

export const logout = () => {
  if (window !== "undefined") {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/login";
  }
};

export const useIsLogin = () => {
  const dispatch = useDispatch();
  const {roles} = useSelector((state) => state.auth);
  const isMyTokenExpired = isExpired(token);

  useEffect(() => {
     dispatch({type: actions.GET_AUTH_USER});
  }, [])


  if(!token){
    return false;
  } else {
     if(isMyTokenExpired){
    localStorage.removeItem("token");
    return false;
    } else {
      return {
        user: true,
        roles,
      }
    }
  }
};
