
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Skeleton from "@mui/material/Skeleton";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/candidateDetail/actions";
import { ignoreOrderCompare } from "../../../utils/arrays";

const DetailsForm = () => {

    const [bioState, setBioState] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [collaborationValues, setCollaborationValues] = useState(undefined)

    const dispatch = useDispatch();

    const collaborationOptions = ["Outsourcing", "Instructor", " Dev Team"]

    const {
        candidate_id,
        firstname,
        lastname,
        bio,
        email,
        resume,
        phone,
        level,
        city,
        country,
        job_title,
        skills,
        languages,
        collaboration,
    } = useSelector((state) => state.candidateDetail.candidate);

    const { loading, pageLoading } = useSelector(
        (state) => state.candidateDetail
    );

    const updateDetail = (e, field, value) => {
        e.preventDefault();
        dispatch({
            type: actions.UPDATE_CANDIDATE_INFO,
            payload:
            {
                [field]: value,
                candidate_id,
            }

        });
    };

    return (
        <div className="details-container">
            <div>
                {!pageLoading ? (
                    <form
                        className="textArea-container"
                        onSubmit={(e) => updateDetail(e, "firstname", firstName)}
                    >
                        <p>
                            {" "}
                            <label htmlFor="lastName">First name :</label>
                        </p>

                        <textarea
                            defaultValue={firstname}
                            sx={{ fontStyle: "Montserrat" }}
                            onChange={(e) => setFirstName(e.target.value)}
                            id="FirstName"
                            name="FirstName"
                            rows="1"
                            cols="20"
                            spellCheck="false"
                        ></textarea>
                        <LoadingButton
                            style={{ margin: "0.75rem 0 0.75rem 0" }}
                            loading={loading}
                            loadingPosition="start"
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            type="submit"
                            disabled={
                                !firstName.trim().length ||
                                    firstName.trim() === firstname
                                    ? true
                                    : false
                            }
                        >
                            Update firstname
                        </LoadingButton>
                    </form>
                ) : (
                    <Skeleton width={170} height={85} />
                )}
                {!pageLoading ? (
                    <form
                        className="textArea-container"
                        onSubmit={(e) => updateDetail(e, "lastname", lastName)}
                    >
                        <p>
                            {" "}
                            <label htmlFor="lastName">Last name :</label>
                        </p>
                        <textarea
                            defaultValue={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                            id="LastName"
                            name="LastName"
                            rows="1"
                            cols="20"
                            spellCheck="false"
                        ></textarea>
                        <LoadingButton
                            style={{ margin: "0.55rem 0 0.35rem 0" }}
                            loading={loading}
                            loadingPosition="start"
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            type="submit"
                            disabled={
                                !lastName.trim().length || lastName.trim() === lastname
                                    ? true
                                    : false
                            }
                        >
                            Update lastname
                        </LoadingButton>
                    </form>
                ) : (
                    <Skeleton width={170} height={85} />
                )}

                {!pageLoading ? (
                    <form
                        className="textArea-container"
                        onSubmit={(e) => updateDetail(e, "collaboration", collaborationValues)}
                    >
                        <p>
                            {" "}
                            <label htmlFor="collaboration">Collaboration :</label>
                        </p>
                        <FormControl sx={{ width: 300 }}>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={collaborationValues || collaboration}
                                onChange={(e) => setCollaborationValues(e.target.value)}
                                input={<OutlinedInput label="Name" />}
                            >
                                {collaborationOptions.map((option) => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <LoadingButton
                            style={{ margin: "0.75rem 0 0.75rem 0" }}
                            loading={loading}
                            loadingPosition="start"
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            type="submit"
                            disabled={
                                !collaborationValues ||
                                ignoreOrderCompare(collaborationValues, collaboration)
                            }
                        >
                            Update collaboration
                        </LoadingButton>
                    </form>
                ) : (
                    <Skeleton width={170} height={85} />
                )
                }
                {!pageLoading ? (
                    <p>
                        Phone number :<span>{phone}</span>
                    </p>
                ) : (
                    <Skeleton width={210} height={45} />
                )}

                {!pageLoading ? (
                    <p>
                        City : <span>{city}</span>
                    </p>
                ) : (
                    <Skeleton width={180} height={45} />
                )}
                {!pageLoading ? (
                    <p>
                        Country : <span>{country}</span>
                    </p>
                ) : (
                    <Skeleton width={184} height={45} />
                )}
            </div>
            <div>

                {!pageLoading ? (
                    //create a button to download pdf from provided link: 
                    <p>
                        Resume :

                        <a style={{ color: "#115f96" }} href={resume} target="_blank" rel="noreferrer" download={`${firstname}_${lastname}_CV`}>Download Resume PDF</a>
                    </p>
                ) : (
                    <Skeleton width={215} height={45} />
                )}

                {!pageLoading ? (
                    <p>
                        Email : <span>{email}</span>
                    </p>
                ) : (
                    <Skeleton width={215} height={45} />
                )}
                {!pageLoading ? (
                    <p>
                        Job title : <span>{job_title}</span>
                    </p>
                ) : (
                    <Skeleton width={200} height={45} />
                )}
                {!pageLoading ? (
                    <p>
                        Skills : <span>{skills?.toString()}</span>
                    </p>
                ) : (
                    <Skeleton width={210} height={45} />
                )}
                {!pageLoading ? (
                    <p>
                        Seniority : <span>{level}</span>
                    </p>
                ) : (
                    <Skeleton width={190} height={45} />
                )}
                {!pageLoading ? (
                    <p>
                        Languages : <span>{languages?.toString()}</span>
                    </p>
                ) : (
                    <Skeleton width={190} height={45} />
                )}
                {!pageLoading ? (
                    <form
                        className="textArea-container"
                        onSubmit={(e) => updateDetail(e, "bioState", bioState)}
                    >
                        <p>
                            <label htmFor="Bio">Bio:</label>
                        </p>
                        <textarea
                            defaultValue={bio}
                            onChange={(e) => setBioState(e.target.value)}
                            id="Bio"
                            name="Bio"
                            rows="7"
                            cols="70"
                        ></textarea>

                        <LoadingButton
                            style={{ margin: "0.75rem 0 0.75rem 0" }}
                            loading={loading}
                            loadingPosition="start"
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            type="submit"
                            disabled={
                                !bioState.trim().length || bioState.trim() === bio
                                    ? true
                                    : false
                            }
                        >
                            Update bio
                        </LoadingButton>
                    </form>
                ) : (
                    <Skeleton variant="rectangular" width={440} height={170} />
                )}
            </div>
        </div>
    )
}

export default DetailsForm