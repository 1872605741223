import { call, put, takeEvery, all } from "redux-saga/effects";
import actions from "./actions";
import notificationActions from "../notification/actions";


export function* getUserInfo(api, { payload }) {
  const {user_id, setFormInputs} = payload;
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        error: null,
      },
    });
  
    try {
      const response = yield call(api.getUser, user_id);
      if (response && response.status === 200) {
        const {firstname, lastname, email, roles} = response.data.data;
  
        yield put({
          type: actions.SET_STATE,
          payload: {
            ...response.data.data,
            loading: false,
          },
        });
        setFormInputs(firstname, lastname, roles, email);
      }
    } catch (error) {
      console.log("error: ", error);
      yield put({
        type: actions.SET_STATE,
        payload: {
          error: error.message,
          loading: false,
        },
      });
    }
  
  };

  export function* getAgentsPaginatedCall(api, { payload }) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        error: null,
      },
    });

    const {current, setSearchParams} = payload;

    try {
      const response = yield call(api.getAgents, {current});
  
      if (response && response.status === 200) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            agentsList: response.data.data,
            currentPage: response.data.current,
            totalPages: response.data.pages,
            loading: false,
          },
        });
        if(setSearchParams){
        setSearchParams();
        }
      }
    } catch (error) {
      console.error("error: ", error);
      yield put({
        type: actions.SET_STATE,
        payload: {
          error: error.message,
          loading: false,
        },
      });
    }
  }

  export function* updateAgentInfo(api, { payload }){
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        error: null,
      },
    });
  
    try {
      const response = yield call(api.updateAgentInfo, payload);
  
      if (response && response.status === 200) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            loading: false,
          },
        });
      }
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Profile updated successfully!",
          severity: "success"
        },
      })
    } catch (error) {
      console.error("error: ", error);
      yield put({
        type: actions.SET_STATE,
        payload: {
          error: error.message,
          loading: false,
        },
      });
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: error.message,
          severity: "error"
        },
      })
    }
  }

  export function* updateAgentRoot(api, { payload }){
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        error: null,
      },
    });
  
    try {
      const response = yield call(api.updateAgentRoot, payload);
  
      if (response && response.status === 200) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            loading: false,
          },
        });
      }
      if(!payload.password){
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Profile info updated successfully!",
          severity: "success"
        },
      })
    }else {
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Password updated successfully!",
          severity: "success"
        },
      })
    }
    } catch (error) {
      console.error("error: ", error);
      yield put({
        type: actions.SET_STATE,
        payload: {
          error: error.message,
          loading: false,
        },
      });
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: error.message,
          severity: "error"
        },
      })
    }
  }

  export function* createAgent(api, { payload }){
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        error: null,
      },
    });
  
    try {
      const response = yield call(api.createAgent, payload);
  
      if (response && response.status === 200) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            loading: false,
          },
        });
      }

      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "User created successfully!",
          severity: "success"
        },
      })
    
    } catch (error) {
      console.error("error: ", error);
      yield put({
        type: actions.SET_STATE,
        payload: {
          error: error.message,
          loading: false,
        },
      });
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: error.message,
          severity: "error"
        },
      })
    }
  }

//Watcher SAGA:
export default function* rootSaga(api) {
  yield all([takeEvery(actions.GET_USER_INFO, getUserInfo, api)]);
  yield all([takeEvery(actions.GET_AGENTS_CALL, getAgentsPaginatedCall, api)]);
  yield all([takeEvery(actions.UPDATE_AGENT_INFO, updateAgentInfo, api)]);
  yield all([takeEvery(actions.UPDATE_AGENT_ROOT, updateAgentRoot, api)]);
  yield all([takeEvery(actions.CREATE_AGENT_CALL, createAgent, api)]);
}
