import actions from "./actions";

const initialState = {
  firstname:"",
  lastname:"",
  roles:[],
  user_id:"",
  agentsList: [],
  currentPage: 1,
  totalPages: 1,
  loading: false,
  pageLoading: true,
  error: null,
};

export default function agentsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
