import React from 'react';
import Chip from '@mui/material/Chip';

const SkillsTags = ({ skills }) => {
  return (
    <div style={{height: 'auto',minWidth:300, maxWidth: 700, display:'flex', flexWrap: 'wrap', padding: '8px 0'}}>
      {skills.map((skill, index) => (
        <Chip
          key={index}
          label={skill}
          variant="outlined"
          size="small"
          color="primary"
          style={{ margin: '4px' }}
        />
      ))}
    </div>
  );
};

export default SkillsTags;