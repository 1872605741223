import React from "react";
import { useEffect, useState } from "react";
import DashboardHeader from "../../components/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/candidateDetail/actions";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import regex from "../../utils/regex";
import Skeleton from "@mui/material/Skeleton";
import LoadingButton from "@mui/lab/LoadingButton";
import EastIcon from "@mui/icons-material/East";
import {useParams, useNavigate, Link} from 'react-router-dom';

function TestCorrection() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    candidate_id,
    picture,
    firstname,
    lastname,
    email,
    phone,
    city,
    country,
    job_title,
    level,
    skills,
  } = useSelector((state) => state.candidateDetail.candidate);
  const { candidateTests } = useSelector((state) => state.candidateDetail);
  const { loading, pageLoading } = useSelector(
    (state) => state.candidateDetail
  );
  const [test, setTest] = useState({
    title: "",
    urls: [],
    sending_date: "",
    end_date: "",
    _id: "",
  });
  const [comment, setComment] = useState("");
  const [urlInputFields, setUrlInputFields] = useState([{ url: "" }]);
  const dateNow = new Date().toISOString().split("T")[0];
  const [submissionDate, setSubmissionDate] = useState(dateNow);
  const [inputError, setInputError] = useState({
    url: true,
    date: false,
  });
  const [score, setScore] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
const {candidateId} = params;

      if (candidate_id) {
      dispatch({
        type: actions.GET_CANDIDATE_DETAILS_CALL,
        payload: { candidate_id },
      });
      dispatch({
        type: actions.GET_CANDIDATE_TESTS_CALL,
        payload: {
          candidate_id,
          status: 0,
        },
      });
    } else if (candidateId) {
      // let candidateId = sessionStorage.getItem("candidate-id");
      dispatch({
        type: actions.GET_CANDIDATE_DETAILS_CALL,
        payload: { candidate_id: candidateId },
      });
      dispatch({
        type: actions.GET_CANDIDATE_TESTS_CALL,
        payload: {
          candidate_id: candidateId,
          status: 0,
        },
      });
    } else {
      navigate("/");
    }
  }, []);

  const handleDateChange = (event) => {
    setSubmissionDate(event.target.value);
    // event.target.value < dateNow
    //   ? setInputError({ ...inputError, date: true })
    //   : setInputError({ ...inputError, date: false });
  };

  const handleUrlChange = (index, event) => {
    const values = [...urlInputFields];
    values[index].url = event.target.value;
    setUrlInputFields(values);
    event.target.value.match(regex.url)
      ? setInputError({ ...inputError, url: false })
      : setInputError({ ...inputError, url: true });
  };

  const handleRemoveFields = (index) => {
    if (urlInputFields.length !== 1) {
      const values = [...urlInputFields];
      values.splice(index, 1);
      setUrlInputFields(values);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch({
      type: actions.EVALUATE_TEST_CALL,
      payload: {
        test_id: test.test_id,
        score,
        submission_date: submissionDate,
        comment,
        sendEmail: checked,
        urls: urlInputFields.map((obj) => obj.url),
        handleSuccess: () => {
          setTest({
            title: "",
            urls: [],
            sending_date: "",
            end_date: "",
            _id: "",
          });
          dispatch({
            type: actions.GET_CANDIDATE_TESTS_CALL,
            payload: {
              candidate_id,
              status: 0,
            },
          });
        },
      },
    });
  };

  return (
    <div>
      <DashboardHeader />
      <h1 style={{ padding: "1rem" }}>Test Correction: </h1>

      {pageLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Skeleton variant="circular" width={96} height={96} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                alignItems: "flex-start",
              }}
            >
              <Skeleton width={180} />
              <Skeleton width={196} />
              <Skeleton width={203} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              alignItems: "flex-start",
            }}
          >
            <Skeleton width={160} />
            <Skeleton width={199} />
            <Skeleton width={152} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {" "}
            <Avatar
              alt={firstname}
              src={picture}
              sx={{ width: 100, height: 100 }}
            />
            <div>
              <p style={{ marginTop: "0" }}>
                <span>Name:</span> {firstname} {lastname}
              </p>
              <p>
                <span>Email:</span> {email}
              </p>
              <p>
                <span>Phone number:</span> {phone}
              </p>
            </div>
          </div>

          <div>
            <p style={{ marginTop: "0" }}>
              <span>Country:</span> {country}, {city}
            </p>
            <p>
              <span>Job Title:</span> {job_title}, {level}
            </p>
            <p>
              <span>Skills:</span> {skills?.toString()}
            </p>
          </div>
          <Button
            endIcon={<EastIcon color="primary" />}
          >
          <Link
          style={{ textDecoration: "none", color: "#0096FF" }}
          to={`/candidate-details/${candidate_id}`}
          >
        Candidate details
            </Link>
          </Button>
        </div>
      )}
      <Divider sx={{ marginTop: "2rem" }} />
      {pageLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Skeleton width={290} height={80} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            padding: "2rem 0",
          }}
        >
          <FormControl style={{ width: "300px" }}>
            <InputLabel htmlFor="test_select">
              {loading
                ? "Loading..."
                : candidateTests?.length
                  ? "Tests"
                  : "This candidate has no active tests.."}
            </InputLabel>
            <Select
              disabled={!candidateTests?.length}
              required
              width="auto"
              labelId="test_select"
              id="test_select"
              value={test.title}
              label="Test"
            >
              {candidateTests?.map((test) => (
                <MenuItem
                  key={test.test_id}
                  value={test.title}
                  onClick={() => setTest(test)}
                >
                  {test.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {test.title ? (
            <FormControl
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                gap: "1rem",
              }}
            >
              <TextField
                id="sending-date"
                label="Sending date"
                value={test.sending_date.split("T")[0]}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                id="deadline"
                label="Deadline"
                value={test.deadline.split("T")[0]}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                id="submission_date"
                label="Submission date"
                type="date"
                value={submissionDate}
                required
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {urlInputFields.map((inputField, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    name="url"
                    label="URL"
                    error={!inputField.url.match(regex.url)}
                    helperText={
                      inputField.url.length === 0
                        ? "This field can't be empty."
                        : !inputField.url.match(regex.url)
                          ? "Please enter a valid url"
                          : ""
                    }
                    value={inputField.url}
                    onChange={(event) => {
                      handleUrlChange(index, event);
                    }}
                  />
                  <IconButton
                    aria-label="delete"
                    color="error"
                    disabled={urlInputFields.length === 1}
                    onClick={() => handleRemoveFields(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}

              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() =>
                  setUrlInputFields([...urlInputFields, { url: "" }])
                }
              >
                Add URL Input
              </Button>
              <TextField
                required
                name="score"
                label="Score"
                type="number"
                value={score}
                onChange={(event) => setScore(parseInt(event.target.value))}
              />
              <div>
                <TextField
                  style={{
                    width: "500px",
                  }}
                  id="comments-input"
                  label="Comments"
                  name="Comments"
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                  multiline
                  minRows={4}
                  maxRows={9}
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                }
                label="Send technical interview email on submit."
              />

              <LoadingButton
                sx={{ width: "200px", margin: "1rem" }}
                loading={loading}
                disabled={Object.values(inputError).includes(true)}
                color="primary"
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </FormControl>
          ) : null}
        </form>
      )}
    </div>
  );
}

export default TestCorrection;
