import { call, put, takeEvery, all, select } from "redux-saga/effects";
import actions from "./actions";

// Saga function for getting global KPI stats
export function* getGlobalStats(api) {
  yield put({
    type: actions.SET_STATE,
    payload: {
        globalStats: {
            data: {},
            loading: true,
            error: null,
        }
    },
  });

  try {
    const response = yield call(api.getGlobalStats);
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
            globalStats: {
                data: response.data,
                loading: false,
            },
        },
      });
    }
  } catch (error) {
    console.log("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        globalStats: {
            data: {},
        loading: false,
        error: error.message,
        }
      },
    });
  }
}
// Saga function for getting KPI stats by Job title
export function* getStatsByJob(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
        statsByJob: {
            data: [],
            loading: true,
            error: null,
        },
    },
  });


  try {
    const response = yield call(api.getStatsByJob, payload);
    if (response && response.status === 200) {
    //   const {firstname, lastname, email, roles, user_id} = response.data.data;
      yield put({
        type: actions.SET_STATE,
        payload: {
            statsByJob: {
                data: response.data,
          loading: false,
            },
        },
      });
    }
  } catch (error) {
    console.log("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        statsByJob: {
        data: [],
        loading: false,
        error: error.message,
        },
      },
    });
  }
}

// Saga function for getting KPI stats by status
export function* getStatsByStatus(api, { payload }) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        statsByStatus: {
          data: [],
          loading: true,
          error: null,
        },
      },
    });
  
    try {
      const response = yield call(api.getStatsByStatus, payload);
      if (response && response.status === 200) {

        yield put({
          type: actions.SET_STATE,
          payload: {
            statsByStatus: {
              data: response.data,
              loading: false,
            },
          },
        });
      }
    } catch (error) {
      console.log("error: ", error);
      yield put({
        type: actions.SET_STATE,
        payload: {
          statsByStatus: {
            data: [],
            loading: false,
            error: error.message,
          },
        },
      });
    }
  }
  
  // Saga function for getting KPI stats by level
  export function* getStatsByLevel(api, { payload }) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        statsByLevel: {
          data: [],
          loading: true,
          error: null,
        },
      },
    });
  
    try {
      const response = yield call(api.getStatsByLevel, payload);
      if (response && response.status === 200) {

        yield put({
          type: actions.SET_STATE,
          payload: {
            statsByLevel: {
              data: response.data,
              loading: false,
            },
          },
        });
      }
    } catch (error) {
      console.log("error: ", error);
      yield put({
        type: actions.SET_STATE,
        payload: {
          statsByLevel: {
            data: [],
            loading: false,
            error: error.message,
          },
        },
      });
    }
  }
  
  export function* getFieldsCall(api) {
    const state = yield select((state) => state.kpi);
  
    try {
      const response = yield call(api.getFilters);
      if (response && response.status === 200) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            filters: { ...state.filters, ...response.data },
          },
        });
      }
    } catch (error) {
      console.error("error: ", error);
      yield put({
        type: actions.SET_STATE,
        payload: {
          error: error.message,
          loading: false,
        },
      });
    }
  }

//Watcher SAGA:
export default function* rootSaga(api) { 
  yield all([takeEvery(actions.GET_GLOBAL_STATS, getGlobalStats, api)]); 
  yield all([takeEvery(actions.GET_STATS_BY_JOB, getStatsByJob, api)]); 
  yield all([takeEvery(actions.GET_STATS_BY_STATUS, getStatsByStatus, api)]); 
  yield all([takeEvery(actions.GET_STATS_BY_LEVEL, getStatsByLevel, api)]); 
  yield all([takeEvery(actions.GET_FIELDS, getFieldsCall, api)]);
}
