import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import actions from "../../redux/candidateDetail/actions";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EastIcon from "@mui/icons-material/East";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import {Link as Linkto } from "react-router-dom";
import {useParams} from 'react-router-dom';

function TestsAccordion() {
  const params = useParams();
  const { candidateTests, loading } = useSelector(
    (state) => state.candidateDetail
  );
  const { candidate_id } = useSelector(
    (state) => state.candidateDetail.candidate
  );
  const [comment, setComment] = useState("");
  const [testId, setTestId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const {candidateId} = params;
    if (candidateId) {
      dispatch({
        type: actions.GET_CANDIDATE_TESTS_CALL,
        payload: { candidate_id: candidateId },
      });
    } else {
      dispatch({
        type: actions.GET_CANDIDATE_TESTS_CALL,
        payload: { candidate_id },
      });
    }
  }, []);

  //   calculate date difference: ----------------
  const calculateDateDiff = (sending_date, submission_date) => {
    const diffInMs =
      new Date(submission_date.split("T")[0]) -
      new Date(sending_date.split("T")[0]);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return diffInDays;
  };

  //Dialog controls : ------------------------
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (test_id) => {
    setTestId(test_id);
    setOpen(true);
  };

  const handleClose = () => {
    setComment("");
    setTestId("");
    setOpen(false);
  };

  // tests accordion controls: ---------------
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //handle Cancel Test: ----------------------

  const handleCancelTest = (test_id) => {
    dispatch({
      type: actions.CANCEL_TEST_CALL,
      payload: {
        test_id,
        comment,
        handleSuccess: () => {
          dispatch({
            type: actions.GET_CANDIDATE_TESTS_CALL,
            payload: { candidate_id },
          });
        },
      },
    });
    handleClose();
  };

  return (
    <div>
      <Divider role="presentation" sx={{ width: "80vw" }} textAlign="left">
        <h2>Candidate tests</h2>{" "}
      </Divider>
      {candidateTests?.map((test, index) => {
        return (
          <div className="test-container">
            <Accordion
              sx={{ width: "700px" }}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  {test.title}
                </Typography>
                <Typography
                  sx={{
                    color:
                      test.status === 0
                        ? "text.secondary"
                        : test.status === 1
                          ? "green"
                          : "#ff1744",
                  }}
                >
                  {test.status === 0
                    ? "Sent"
                    : test.status === 1
                      ? "Done"
                      : "Cancelled"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {test.submission_date && (
                    <p>
                      Score: <span>{test.score}</span>
                    </p>
                  )}
                  <p>
                    Sending date: <span>{test.sending_date.split("T")[0]}</span>
                  </p>
                  <p>
                    Deadline: <span>{test.deadline.split("T")[0]}</span>
                  </p>
                  {test.submission_date && (
                    <p>
                      Submission date:{" "}
                      <span>{test.submission_date.split("T")[0]}</span>
                    </p>
                  )}
                  {test.submission_date && (
                    <p>
                      Duration:{" "}
                      <span>
                        {calculateDateDiff(
                          test.sending_date,
                          test.submission_date
                        ) + " days"}
                      </span>
                    </p>
                  )}
                  {test.status !== 0 && (
                    <p>
                      Comments:{" "}
                      <span style={{ color: !test.comment ? "grey" : "" }}>
                        {test.comment
                          ? test.comment
                          : "No comments were provided."}
                      </span>
                    </p>
                  )}
                  {test.submission_date && (
                    <p>
                      urls:
                      <ul
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "1rem",
                          backgroundColor: "#F9F9F9",
                          listStyle: "none",
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                          marginTop: "0 ",
                          borderRadius: "5px",
                        }}
                      >
                        {test.urls.map((url) => {
                          return (
                            <li>
                              <Link
                                href={url}
                                target="_blank"
                                rel="noreferrer"
                                underline="always"
                              >
                                {url}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </p>
                  )}
                  {test.status === 0 && (
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleClickOpen(test.test_id)}
                      >
                        Cancel test
                      </Button>
                      <Button
                        endIcon={<EastIcon color="primary" />}
                      >
                      <Linkto
                      style={{ textDecoration: "none", color: "#0096FF" }}
                      to={`/test-correction/${candidate_id}`}
                    >
                        Evaluate test
                        </Linkto>
                      </Button>
                    </div>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Cancel test</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to <span style={{ color: "#ff1744" }}> cancel </span>
            this test. Are you sure?
            <br />
            Please enter your comments below.
          </DialogContentText>

          <TextField
            multiline
            minRows={1}
            maxRows={10}
            autoFocus
            margin="dense"
            id="comments"
            label="comments"
            type="text"
            fullWidth
            error={!comment.trim().length}
            helperText={!comment.trim().length ? "Comments are required" : ""}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton color="error" onClick={handleClose}>
            Cancel
          </LoadingButton>
          <LoadingButton
            disabled={!comment.trim().length}
            loading={loading}
            onClick={() => handleCancelTest(testId)}
          >
            Cancel test
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TestsAccordion;
