import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import agentActions from "../../redux/agents/actions";
import authActions from "../../redux/auth/actions";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ROLES } from "../../constants/userRoles";
import {useLocation, useParams} from "react-router-dom";

function UpdateUserForm() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const isUpdateProfile = location.pathname==="/profile"
  const { lastname, firstname, email, roles } = useSelector((state) => isUpdateProfile ? state.auth : state.agents) ;
  const {loading} = useSelector((state) => state.agents);
  const authState = useSelector((state) => state.auth);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [Role, setRole] = useState("");
  const [Email, setEmail] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [userPasswords, setUserPasswords] = useState({});

useEffect(() => {
if(isUpdateProfile) { 
    setFirstName(firstname);
    setLastName(lastname);
    setRole(roles);
    setEmail(email);
} 
}, [firstname, lastname, roles, email])


useEffect(() => {
  if(!isUpdateProfile){
  let {user_id} = params;
  dispatch({type:agentActions.GET_USER_INFO, payload:{
    user_id,
    setFormInputs: (firstname, lastname, roles, email) => {
      setFirstName(firstname);
      setLastName(lastname);
      setRole(roles);
      setEmail(email);
    }
  }})
  
  }
}, [])

  const updateInfo = (e) => {
    e.preventDefault();
    if(!isUpdateProfile){
      let {user_id} = params;
      // dispatch updateUser :: ROOT , for password and info ;
      dispatch({type: agentActions.UPDATE_AGENT_ROOT, payload: {
        firstname: userInfo.firstName,
        lastname: userInfo.lastName,
        roles: userInfo.roles,
        user_id,
      }
    });
    } else {
      dispatch({type: agentActions.UPDATE_AGENT_INFO, payload: {
        user_id: authState.user_id,
        firstname: userInfo.firstName,
        lastname: userInfo.lastName,
      }})
    }
  };

  const updatePassword = (e) => {
    e.preventDefault();
    // dispatch updateUser :: ROOT for Password
    if(isUpdateProfile){
      dispatch({type: agentActions.UPDATE_AGENT_ROOT, payload: {
        password: newPassword,
        user_id: authState.user_id,
      }
    })
    } else {
      let {user_id} = params;
      dispatch({type: agentActions.UPDATE_AGENT_ROOT, payload: {
        password: newPassword,
        user_id,
      }
    })
    }
    
  }

  return (
    <Box>
      <form
        style={{
          padding: "3rem",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
        }}
        onSubmit={updateInfo}
      >
        <TextField
          style={{ flex: "1 1 30vw" }}
          id="firstname"
          label="First name"
          required
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />
        <TextField
          style={{ flex: "1 1 30vw" }}
          id="lastname"
          label="Last name"
          required
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
        />
        <TextField
          style={{ flex: "1 1 30vw" }}
          id="email"
          label="Email Address"
          type="email"
          disabled
          value={Email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <FormControl style={{ flex: "1 1 30vw" }}>
          <InputLabel id="role-select">Role</InputLabel>
          <Select
            labelId="role-select"
            id="role-select"
            disabled={isUpdateProfile || !authState?.roles?.includes("root")}
            value={Role}
            label="Role"
            onChange={(event) => setRole(event.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"moderator"}>Moderator</MenuItem>
            <MenuItem value={"root"}>Admin</MenuItem>
          </Select>
        </FormControl>

        <LoadingButton
          style={{ width: "40vw" }}
          disabled={!firstName || !lastName || !Role}
          onClick={() => {
            setUserInfo({
              firstName,
              lastName,
              roles : Role,
            });
          }}
          loading={loading}
          variant="contained"
          type="submit"
        >
          <span>Update profile</span>
        </LoadingButton>
      </form>

      
{authState?.roles?.includes("root") &&
<>
<Divider><span>Update Password</span></Divider>
      <form 
      onSubmit={updatePassword}
      style={{
        padding: "3rem",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
      }}
      >
    {/* 
       <TextField
          style={{
            alignSelf: "flex-start",
            flex: "1 1 30vw",
          }}
          id="pwd"
          label="Current Password"
          helperText={"Please enter your current password."}
          type="password"
          required
          value={oldPassword}
          onChange={(event) => {
            setOldPassword(event.target.value);
          }}
        />
    */}

        <TextField
          style={{
            alignSelf: "flex-start",
            flex: "1 1 30vw",
            marginBottom: "1rem",
          }}
          id="pwd"
          label="New password"
          helperText={
            newPassword !== confirmPassword
              ? "Password must match"
              : "Please enter a secure password."
          }
          error={newPassword !== confirmPassword}
          type="password"
          required
          value={newPassword}
          onChange={(event) => {
            setNewPassword(event.target.value);
          }}
        />
        <TextField
          style={{
            alignSelf: "flex-start",
            flex: "1 1 30vw",
            marginBottom: "1rem",
          }}
          id="confirm-pwd"
          label="Confirm Password"
          helperText={
            confirmPassword !== newPassword ? "Passwords must match" : null
          }
          error={confirmPassword !== newPassword}
          type="password"
          required
          value={confirmPassword}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
          }}
        />
        <LoadingButton
        style={{ width:"40vw" }}
          disabled={
            newPassword !== confirmPassword 
          }
          onClick={() => {
            setUserPasswords({
            //   oldPassword,
              newPassword,
            });
          }}
          loading={loading}
          variant="contained"
          type="submit"
        >
          <span>Update Password</span>
        </LoadingButton>
      </form>
      </>
    }
    </Box>
  );
}

export default UpdateUserForm;
