import React from 'react';
import { Typography, Button, Box } from '@mui/material';

const EmptyStateComponent = ({error}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2}} textAlign="center" py={3}>
      <Typography variant="h5" color="textSecondary">
        No data available
      </Typography>
      <Typography variant="body1" color="textSecondary">
        An error occurred or there is no data to display.
      </Typography>
      <Button onClick={() => window.location.reload()} variant="contained" color="primary" sx={{width: 200}}>
        Refresh page
      </Button>
    </Box>
  );
};

export default EmptyStateComponent;
