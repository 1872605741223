import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';

function FilterBar({ title, dispatchType, data }) {
  const dispatch = useDispatch();
  const [stackOffset, setStackOffset] = React.useState('');

  const handleStackOffsetChange = (event) => {
    const selectedValue = event.target.value;

    setStackOffset(selectedValue);
    // Make the API call with the selected value as a parameter
    dispatch({
      type: dispatchType,
      payload: {
        [title === 'Job title' ? 'job_title' : 'level']: selectedValue,
      },
    });
  };

  return (
    <TextField
      sx={{ minWidth: 150, mr: 5 }}
      select
      label={title}
      value={stackOffset}
      onChange={handleStackOffsetChange}
    >
      <MenuItem key={'All candidates'} value={''}>
        All candidates
      </MenuItem>
      {data?.map((job) => (
        <MenuItem key={job} value={job}>
          {job}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default FilterBar;
