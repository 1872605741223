import UserIcon from "../assets/icons/user.png";
import TestIcon from "../assets/icons/test.png";
import HRIcon from "../assets/icons/hr.png";
import DataIcon from "../assets/icons/data-assessment.png";
import routesList from "../routes/routesList";


const sidebar_menu = [
  {
    id: 1,
    icon: UserIcon,
    path: routesList.candidates,
    title: "Candidates",
  },
  {
    id: 2,
    icon: TestIcon,
    path: routesList.candidateTests,
    title: "Test Stage",
  },
  {
    id: 3,
    icon: TestIcon,
    path: routesList.candidatesTech,
    title: "Tech Interview Stage",
  },
  {
    id: 4,
    icon: HRIcon,
    path: routesList.candidatesHR,
    title: "HR Interview Stage",
  },
  {
    id: 8,
    icon: DataIcon,
    path: routesList.kpis,
    title: "K.P.Is",
  },
];

export default sidebar_menu;
