export const CANDIDATE_STATUS = {
    PENDING: 0,
    TEST_STAGE: 1,
    TECH_INTERVIEW_STAGE: 2,
    HR_INTERVIEW_STAGE: 3,
    OFFER_SENT: 4,
    APPROVED: 5,
    ON_HOLD: 6,
    REJECTED: 7,
    NOT_INTERESTED: 8,
}
export const CANDIDATE_STATUS_TEXT = {
    0: "Pending",
    1: "Test stage",
    2: "Tech interview stage",
    3: "HR interview stage",
    4: "Offer sent",
    5: "Approved",
    6: "On hold",
    7: "Rejected",
    8: "Not Interested"
}

export const getStatusKeyByValue = (value) => {
    return Object.keys(CANDIDATE_STATUS_TEXT).find(key => CANDIDATE_STATUS_TEXT[key] === value);
}