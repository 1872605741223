import actions from "./actions";

const initialState = {
  candidates: [],
  currentPage: 1,
  totalPages: 1,
  filters: {
    skills: [],
    jobs: [],
    countries: [],
    status: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    level: [
      {
        label: 'Junior',
        value: 'Junior (0-2 years experience)'
      },
      {
        label: 'Mid',
        value: 'Mid senior (3-5 years experience)'
      },
      {
        label: 'Senior',
        value: 'Senior (>5 years experience)'
      },
     ],
  },
  selectedFilters: {
    skills: [],
    jobs: [],
    countries: [],
    status: undefined,
    level: [],
  },
  query: "",
  loading: true,
  action: {
    loading: false,
    error: null,
  },
  error: null,
};

export default function candidatesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
