import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/agents/actions";
import DashboardHeader from '../../components/DashboardHeader';
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import Pagination from "@mui/material/Pagination";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import { purple, blue } from "@mui/material/colors";
import {Link, useSearchParams} from "react-router-dom";
import "../styles.css";




function ManageAgents() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const {
        agentsList, 
        loading,
        error,
        currentPage,
        totalPages, 
    } = useSelector((state) => state.agents);
    const authState = useSelector((state) => state.auth);
 
  useEffect(() => {
   
    dispatch({
        type: actions.GET_AGENTS_CALL,
        payload: {current: page}
    })

  }, []);

  const handlePageChange = (event, value) => {
    dispatch({
      type: actions.GET_AGENTS_CALL,
      payload: {
        current: value, 
        setSearchParams: () => {
          setSearchParams({page: value})
        }
      }
  })
  }
    
  return (
    <div className="dashboard-content">
      <DashboardHeader />
      {authState.loading ? (
        <Box style={{ display: 'flex', width:"100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box> 
      ):(
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Manage Agents</h2>
        </div>
        <table>
          <thead>
            <th style={{ textAlign: "center" }}>NAME</th>
            <th style={{ textAlign: "center" }}>EMAIL</th>
            <th style={{ textAlign: "center" }}>ROLE</th>
            <th style={{ textAlign: "center" }}>ACTION</th>
          </thead>

          {loading ? (
            <>
              <td></td>

              <div className="dashboard-content-footer">
                <span className="empty-table">
                  <CircularProgress />
                  <br />
                  Loading...
                </span>
              </div>
              <td></td>
            </>
          ) : agentsList?.length !== 0 ? (
            <tbody>
              {agentsList.map((agent, index) => {
                if (agent.email !== authState.email) {
                  return (
                    <tr key={index}>
                      <td>
                        <div>
                          <Avatar
                            className="dashboard-content-avatar"
                            alt={agent?.firstname}
                            src={agent?.picture}
                            sx={{ bgcolor: agent?.roles?.includes("root") ? purple[600] : blue[400]}}

                          />
                          <span className="td-span">
                            {agent?.firstname} {agent.lastname}
                          </span>
                        </div>
                      </td>
                      <td style={{textAlign: 'center'}}>
                        <span className="td-span">{agent?.email}</span>
                      </td>
                      <td style={{textAlign:"center"}}>
                        <span className="td-span">{agent?.roles}</span>
                      </td>
                      <td style={{textAlign: 'center'}}>
                        <Button
                          variant="outlined"
                          endIcon={<EastIcon color="#0096FF" />}
                        >
                          <Link
                            style={{ textDecoration: "none", color: "#0096FF" }}
                            to={`/update-agent/${agent.user_id}`}
                          >
                            UPDATE
                          </Link>
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          ) : null}
        </table>

        {loading ? (
          <div className="dashboard-content-footer">
            <span className="empty-table">. . .</span>
          </div>
        ) : error ? (
          <div className="dashboard-content-footer">
            <span className="empty-table" style={{ color: "red" }}>
              {error}
            </span>
          </div>
        ) : agentsList.length === 0 && !loading ? (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <Pagination
              count={totalPages}
              defaultPage={1}
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              shape="rounded"
              color="primary"
              siblingCount={2}
              boundaryCount={2}
            />
          </div>
        )}
      </div>
      )}
    </div>
  );
}

export default ManageAgents