import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../auth/login";
import {
    CandidateDetails,
    Candidates,
    TestCorrection,
    Profile,
    CreateAgent,
    ManageAgents,
    UpdateAgent,
    UnAuthorized,
    KPI,
    Error,
} from "../pages";
import routesList from "./routesList";
import PrivateRoutes from "./PrivateRoutes";

const Routess = (
    <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/401" element={<UnAuthorized/>} />
        <Route exact path={routesList.login} element={<Login />} />
        <Route element={<PrivateRoutes allowedRoles={["root"]} />}>
            <Route path={routesList.createAgent} element={<CreateAgent/>} />
            <Route path={`${routesList.updateAgent}/:user_id`} element={<UpdateAgent/>} />
            <Route path={routesList.manageAgents} element={<ManageAgents/>} />
         </Route>

        <Route element={<PrivateRoutes allowedRoles={["moderator", "root"]} />}>
            <Route exact path={routesList.candidates} element={<Candidates />} />
            <Route exact path={routesList.candidatesTech} element={<Candidates />} />
            <Route exact path={routesList.candidateTests} element={<Candidates />} />
            <Route exact path={routesList.candidatesHR} element={<Candidates />} />
            <Route exact path={routesList.kpis} element={<KPI />} />
            <Route exact path={`${routesList.candidateDetails}/:candidateId`} element={<CandidateDetails />} />
            <Route exact path={`${routesList.testCorrection}/:candidateId`} element={<TestCorrection />} />
            <Route path={routesList.profile} element={<Profile/>} />
        </Route>
    </Routes>

);

export default Routess;