import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import {Link} from 'react-router-dom';


function Error() {
  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
    }}
  >
    <Typography variant="h1">
      404
    </Typography>
    <Typography variant="h6" >
      The page you’re looking for doesn’t exist.
    </Typography>
    <Button variant="contained">
    <Link
    style={{ textDecoration: "none", color: "#ffff" }}
    to={`/`}
     >
      Back Home
      </Link>
    </Button>
  </Box>
  )
}

export default Error