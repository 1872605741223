import addFriend from "../assets/icons/add-friend.png";
import group from "../assets/icons/group.png";
import routesList from "../routes/routesList";

const sidebar_menu_root = [
  {
    id: 5,
    icon: group,
    path: routesList.manageAgents,
    title: "Manage Agents",
  },
  {
    id: 6,
    icon: addFriend,
    path: routesList.createAgent,
    title: "Create Agent",
  },
];

export default sidebar_menu_root;
