import axios from "axios";
import config from "../config";

const url = config.app.apiUrl;
const token = localStorage.getItem("token");

const create = (baseURL = url) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: token,
  };
  const api = axios.create({
    baseURL,
    headers,
  });

/* ------------------------------- CANDIDATES ------------------------------- */
  const getCandidates = (params) => api.get("/candidates/paginate", { params });

  const exportCandidates = (params) =>
    api.get("/candidates/export", { params });

  const getFilters = () => api.get("/candidates/filters");

  const updateCandidate = (id, data) => api.patch(`/candidates/${id}`, data);

  const getCandidateDetails = (id) => api.get(`/candidates/${id}`);

  const deleteCandidate = (id) => api.delete(`/candidates/${id}`);

/* ---------------------------------- TESTS --------------------------------- */
  const getTests = () => api.get(`/tests`);

  const getCandidateTests = (id, params) => api.get(`/tests/${id}`, { params });

  const sendTest = (id, data) => api.post(`/tests/sendTest/${id}`, data);

  const evaluateTest = (id, data) =>
    api.post(`/tests/evaluateTest/${id}`, data);

  const cancelTest = (id, data) => api.post(`/tests/cancelTest/${id}`, data);

  const sendTechInterviewEmail = (id) =>
    api.get(`/candidates/sendTechInterviewEmail/${id}`);

  const sendHRInterviewEmail = (id) =>
    api.get(`/candidates/sendHRInterviewEmail/${id}`);

  const sendAcceptanceEmail = (id) =>
    api.get(`/candidates/sendAcceptanceEmail/${id}`);

/* --------------------------------- AGENTS --------------------------------- */

  const getUser = (id) => api.get(`/user/${id}`);

  const getAgents = (params) => api.get("/user/paginate", { params });

  const updateAgentInfo = (payload) =>
    api.patch(`/user/info/${payload.user_id}`, payload);

  const updateAgentRoot = (payload) =>
    api.patch(`/user/${payload.user_id}`, payload);

  const createAgent = (user) => api.post("/user", user);

  /* ---------------------------------- KPIs ---------------------------------- */
  const getGlobalStats = () => api.get(`/candidates/getTotalStatistics`);

  const getStatsByJob = (params) => api.get(`/candidates/getCandidateCountByJobTitle`, { params });

  const getStatsByStatus = (params) => api.get(`/candidates/getCandidateCountByStatus`, { params });

  const getStatsByLevel = (params) => api.get(`/candidates/getCandidateCountByLevel`, { params });

  return {
    getCandidates,
    getFilters,
    getCandidateDetails,
    getCandidateTests,
    updateCandidate,
    deleteCandidate,
    getTests,
    sendTest,
    evaluateTest,
    cancelTest,
    sendTechInterviewEmail,
    sendHRInterviewEmail,
    sendAcceptanceEmail,
    getUser,
    getAgents,
    updateAgentInfo,
    updateAgentRoot,
    createAgent,
    exportCandidates,
    /* ---------------------------------- kpis ---------------------------------- */
    getGlobalStats,
    getStatsByJob,
    getStatsByStatus,
    getStatsByLevel,

  };
};

export default {
  create,
};