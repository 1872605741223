import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import actions from "../../../redux/candidateDetail/actions";

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const DeleteModal = ({ open, setOpen }) => {
    const dispatch = useDispatch();

    const {
        candidate_id,
        firstname,
        lastname,
        email
    } = useSelector((state) => state.candidateDetail.candidate);

    const [deletionEmail, setDeletionEmail] = useState("");

    const handleDeleteCandidate = () => {
        if (deletionEmail === email) {
            dispatch({ type: actions.DELETE_CANDIDATE_CALL, payload: { candidate_id } });
        }
        setOpen(false)
    };

    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle color="primary">Delete Candidate</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To delete{" "}
                        <span style={{ color: "#1A76D2" }}>
                            {firstname} {lastname}
                        </span>
                        , please enter the candidate's email address below.{" "}
                        <span style={{ color: "#1A76D2" }}>{email}</span>
                    </DialogContentText>
                    <TextField
                        onChange={(e) => setDeletionEmail(e.target.value)}
                        autoFocus
                        error={deletionEmail !== email}
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        disabled={deletionEmail !== email}
                        color="error"
                        onClick={handleDeleteCandidate}
                    >
                        Delete Candidate
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeleteModal