const actions = {
    SET_STATE: "agents/SET_STATE",
    RESET_STATE: "candidateDetail/RESET_STATE",
    GET_USER_INFO: "agents/GET_USER_INFO",
    GET_AGENTS_CALL: "agents/GET_AGENTS_CALL",
    UPDATE_AGENT_INFO: "agents/UPDATE_AGENT_INFO",
    UPDATE_AGENT_ROOT: "agents/UPDATE_AGENT_ROOT",
    CREATE_AGENT_CALL: "agents/CREATE_AGENT_CALL",
  };
  
  export default actions;
  