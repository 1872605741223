import { call, put, takeEvery, all } from "redux-saga/effects";
import { login } from "../../utils/auth";
import actions from "./actions";

export function* loginCall(api, { payload }) {
  // const state = yield select((state) => state.auth);
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      error: null,
    },
  });

  try {
    const response = yield call(api.signin, payload);
    if (response && response.status === 200) {
      const {token} = response.data
      const { firstname, lastname, email, roles, user_id } = response.data.data;
      yield put({
        type: actions.SET_STATE,
        payload: {
          firstname,
          lastname,
          email,
          roles,
          user_id,
          token,
          loading: false,
        },
      });
      login(token, { ...response.data.data });
      localStorage.setItem("token", token);
      window.location.href = "/";
    }
  } catch (error) {
    console.log("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
  }
}

export function* getAuthUser(api) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      error: null,
    },
  });


  try {
    const response = yield call(api.getAuthUser);
    if (response && response.status === 200) {
      const {firstname, lastname, email, roles, user_id} = response.data.data;
      yield put({
        type: actions.SET_STATE,
        payload: {
          firstname,
          lastname,
          email,
          roles,
          user_id,
          loading: false,
        },
      });
    }
  } catch (error) {
    console.log("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

//Watcher SAGA:
export default function* rootSaga(api) { 
  yield all([takeEvery(actions.LOGIN_CALL, loginCall, api)]); 
  yield all([takeEvery(actions.GET_AUTH_USER, getAuthUser, api)]); 
}
