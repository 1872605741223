import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { useState } from "react";

const DeleteButton = () => {
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
    };

    return (
        <>
            <Button
                variant="contained"
                color="error"
                onClick={handleOpenDelete}
            >
                Delete Candidate
            </Button>
            <DeleteModal open={openDelete} setOpen={setOpenDelete} />

        </>

    )
}

export default DeleteButton