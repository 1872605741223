import { BrowserRouter as Router } from "react-router-dom";

//Redux store configuration:
import { Provider } from "react-redux";
import CreateStore from "./redux/reducers";
import Notification from "./components/Notification";
import SideBar from "./components/Sidebar";
import sidebar_menu from "./constants/sidebar-menu";
import sidebar_menu_root from "./constants/sidebar-menu-root";

import "./App.css";
import sidebar_menu_offline from "./constants/sidebar-offline-menu";
import Routes from "./routes/Routes";

function App() {
  const store = CreateStore();
  const token = localStorage.getItem("token");

  return (
    <Router>
      <Provider store={store}>
        <div className="dashboard-container">
          {token ? (
            <SideBar
              menu={token ? sidebar_menu : sidebar_menu_offline}
              rootMenu={sidebar_menu_root}
            />
          ) : (
            ""
          )}

          <div className="dashboard-body">{Routes}</div>
          <Notification />
        </div>
      </Provider>
    </Router>
  );
}

export default App;

