import React from 'react';
import UpdateUserForm from "../../components/UpdateUserForm";
import DashboardHeader from '../../components/DashboardHeader';

function Profile() {
  return (
    <div className="dashboard-content">
      <DashboardHeader />
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>User Profile</h2>

        </div>
    <UpdateUserForm/>
    </div>
    </div>
  )
}

export default Profile;