import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const KpiCard = ({ title, value, loading, error }) => (
  <Card style={{ flex: 1 }}>
    <CardContent>
      {loading ? (
        <CircularProgress />
      ) : (
        <Typography variant='h4'>{value}</Typography>
      )}
      <Typography variant='subtitle2'>{title}</Typography>
    </CardContent>
  </Card>
);

function GlobalStats() {
  const {
    globalStats: {
      data: { candidates, jobs, skills },
      loading,
      error,
    },
  } = useSelector((state) => state.kpi);
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <KpiCard
        title='Total Candidates'
        loading={loading}
        value={error ? '--' : candidates}
        icon='candidate-icon.png'
      />
      <KpiCard
        title='Job titles'
        loading={loading}
        value={error ? '--' : jobs}
        icon='job-icon.png'
      />
      <KpiCard
        title='Skills'
        loading={loading}
        value={error ? '--' : skills}
        icon='skills-icon.png'
      />
    </div>
  );
}

export default GlobalStats;
