import React, {useState, useEffect} from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import FilterBar from './FilterBar';
import EmptyStateComponent from './ErrorComponent';
import SpinnerComponent from './SpinnerComponent';


function BasicBarChart({ title, hasFilter, dispatchType, filterLabel, filterData, data, loading, error }) {
  const [counts, setCounts] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
  if(data.length){
    const counts = data.map(item => item?.value);
    const labels = data.map(item => item?.label);
    setCounts(counts);
    setLabels(labels);
  }
  }, [data])


    
  const colorPalette = ['#3498db'];

  return (
    <Box
      sx={{
        minWidth: 530,
        maxWidth: 530,
        height: 420,
        borderRadius: 4,
        border: '1px solid lightgray',
        padding: 2,
      }}
    >
      <h2>{title}</h2>
      {hasFilter && <FilterBar title={filterLabel} dispatchType={dispatchType} data={filterData}/>}
      {loading ? <SpinnerComponent/> : error ? <EmptyStateComponent error={error}/> : counts.length && labels.length &&
      <BarChart
        colors={colorPalette}
        xAxis={[
          {
            scaleType: 'band',
            data: labels,
          },
        ]}
        series={[
          {
            data: counts,
            type: 'bar',
          },
        ]}
        width={530}
        height={300}
        loading={true}
      />
    }
    </Box>
  );
}

export default BasicBarChart;
