import { all } from "redux-saga/effects";
import API_AUTH from "../services/apiAuth";
import API from "../services/api";

//import all sagas:
import auth from "./auth/sagas";
import candidates from "./candidates/sagas";
import candidateDetail from "./candidateDetail/sagas";
import agents from "./agents/sagas";
import kpi from "./kpi/sagas";
import notification from "./notification/sagas";

//assemble the sagas:
export default function* rootSaga() {
  const apiAuth = yield API_AUTH.create();
  const api = yield API.create();

  yield all([auth(apiAuth), candidates(api), candidateDetail(api), agents(api), kpi(api), notification()]);
}
