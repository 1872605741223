import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/agents/actions";
import {ROLES} from "../../constants/userRoles";

function CreateUserForm() {
  const dispatch = useDispatch(); 
  const { loading } = useSelector((state) => state.agents);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [user, setUser] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({type : actions.CREATE_AGENT_CALL, 
      payload:
      {
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      roles: user.role,
      password: user.password
      }
     });

  }

  return (
    <form style={{ padding:"3rem",display:"flex", flexDirection:"row",flexWrap:"wrap",justifyContent:"center", alignItems:"center", gap:"2rem"}} onSubmit={handleSubmit}>
    <TextField
    style={{flex: "1 1 30vw"}}
    id="firstname"
    label="First name"
    required
    value={firstName}
    onChange={(event) => {
      setFirstName(event.target.value);
    }}
  />
  <TextField
  style={{flex: "1 1 30vw"}}
  id="lastname"
  label="Last name"
  required
  value={lastName}
  onChange={(event) => {
    setLastName(event.target.value);
  }}
/>
<TextField
style={{flex: "1 1 30vw"}}
id="email"
label="Email Address"
type="email"
required
value={email}
onChange={(event) => {
  setEmail(event.target.value);
}}
/>
<FormControl required style={{flex: "1 1 30vw"}}>
<InputLabel id="role-select">Role</InputLabel>
<Select
  labelId="role-select"
  id="role-select"
  required
  value={role}
  label="Role"
  onChange={(event) => setRole(event.target.value)}
>
  <MenuItem value="">
    <em>None</em>
  </MenuItem>
  <MenuItem value={"Moderator"}>Moderator</MenuItem>
  <MenuItem value={"Admin"}>Admin</MenuItem>
</Select>

</FormControl>

<TextField
style={{alignSelf:"flex-start", flex: "1 1 30vw", marginBottom: "1rem"}}
id="pwd"
label="Password"
helperText={password !== confirmPassword ? "Password must match" : "Please enter a secure password."}
error={password !== confirmPassword}
type="password"
required
value={password}
onChange={(event) => {
  setPassword(event.target.value);
}}
/>
<TextField
style={{alignSelf:"flex-start", flex: "1 1 30vw", marginBottom:"1rem"}}
id="confirm-pwd"
label="Confirm Password"
helperText={confirmPassword !== password ? "Passwords must match" : null}
error={confirmPassword !== password}
type="password"
required
value={confirmPassword}
onChange={(event) => {
  setConfirmPassword(event.target.value);
}}
/>

<LoadingButton
style={{width:"30vw"}}
disabled={password !== confirmPassword || !firstName || !lastName || !email || !role}
onClick={() => { setUser({
  firstName,
  lastName,
  email,
  role: ROLES[role],
  password
})}}
loading={loading}
variant="contained"
type="submit"
>
<span>Create Agent</span>
</LoadingButton>

</form>
  )
}

export default CreateUserForm