import { useSelector, useDispatch } from "react-redux";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import actions from "../../redux/notification/actions";

const Notification = () => {
    const dispatch = useDispatch();

    const { open, message, severity } = useSelector(
        (state) => state.notification
    );

    return (
        <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={() => {
                dispatch({
                    type: actions.SET_STATE,
                    payload: { open: false },
                });
            }}
        >
            <Alert severity={severity} sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default Notification