import actions from "./actions";

const initialState = {
  candidate: {},
  tests: [],
  candidateTests: [],
  loading: false,
  pageLoading: true,
  error: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
