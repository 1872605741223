import actions from "./actions";

const initialState = {
  firstname: "",
  lastname: "",
  email: "",
  user_id: "",
  roles: ["root"],
  token: "",
  loading: false,
  error: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

