import { downloadFile } from "../../utils/downloadFile";
import actions from "./actions";
import {
  takeLatest,
  takeEvery,
  put,
  call,
  select,
  all,
} from "redux-saga/effects";
import moment from 'moment';

export function* getFiltersCall(api) {
  const state = yield select((state) => state.candidates);

  try {
    const response = yield call(api.getFilters);
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          filters: { ...state.filters, ...response.data },
        },
      });
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
  }
}

export function* getCandidatesCall(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      error: null,
    },
  });
  //setPage is to be removed (replaced by setUrlParams)
  const { setPage, setSearchParams } = payload;

  try {
    const response = yield call(api.getCandidates, payload);

    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          candidates: response.data.data,
          currentPage: response.data.current,
          totalPages: response.data.pages,
          loading: false,
        },
      });
      if (setPage) {
        setPage();
      }
      if (setSearchParams) {
        setSearchParams();
      }
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
  }
}

export function* exportCondidateCall(api, { payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      action: {
        loading: true,
        error: null,
      },
    },
  });
  //setPage is to be removed (replaced by setUrlParams)
  const { setSearchParams } = payload;

  try {
    const response = yield call(api.exportCandidates, payload);
    const title = `CandidatesList_${moment().format('DD_MM_YYYY_h:mm:ss')}.csv`;
    const data = response?.data;
    downloadFile(data, title);

    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          action: {
            loading: false,
            error: null,
          }
        },
      });
      if (setSearchParams) {
        setSearchParams();
      }
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        action: {
        loading: false,
        error: error.message,
      }
      },
    });
  }
}

//Watcher SAGA:
export default function* rootSaga(api) {
  yield all([takeLatest(actions.GET_CANDIDATES_CALL, getCandidatesCall, api)]);
  yield all([takeEvery(actions.GET_FILTERS_CALL, getFiltersCall, api)]);
  yield all([
    takeEvery(actions.EXPORT_CANDIDATES_CALL, exportCondidateCall, api),
  ]);
}
