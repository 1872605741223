const actions = {
  SET_STATE: "candidates/SET_STATE",
  RESET_STATE: "candidates.RESET_STATE",
  GET_USER_CALL: "candidates/GET_USER_CALL",
  GET_CANDIDATES_CALL: "candidates/GET_CANDIDATES_CALL",
  GET_FILTERS_CALL: "candidates/GET_FILTERS_CALL",
  GET_CANDIDATE: "candidates/GET_CANDIDATE",
  EXPORT_CANDIDATES_CALL: "candidates/EXPORT_CANDIDATES_CALL",
};

export default actions;
