import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@material-ui/core";
import actions from "../../../redux/candidateDetail/actions";
import { CANDIDATE_STATUS } from "../../../constants/candidateStatus";

const AddCommentModal = ({ open, setOpen, isTechPage, isHRPage }) => {
    const { candidate } = useSelector((state) => state.candidateDetail);

    const [comments, setComments] = useState(undefined);
    const status = isTechPage ? CANDIDATE_STATUS.TECH_INTERVIEW_STAGE : isHRPage ? CANDIDATE_STATUS.HR_INTERVIEW_STAGE : null
    const dispatch = useDispatch();

    const handleUpdateStatus = (id, status, comments) => {
        dispatch({
            type: actions.UPDATE_CANDIDATE_STATUS_CALL,
            payload: {
                status,
                candidate_id: id,
                comments,
            },
        });
    };
    return (
        <div>
            <Dialog open={open} onClose={() => { setOpen(false); }}>
                <DialogTitle color="primary">Add comment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add comments about the technical interview of {" "}
                        <span style={{ color: "#1A76D2" }}>
                            {candidate?.firstname} {candidate?.lastname}
                        </span>

                    </DialogContentText>
                    <TextField
                        onChange={(e) => setComments(e.target.value)}
                        autoFocus
                        margin="dense"
                        id="add comment"
                        label="Comments"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false); setComments(undefined); }}>Cancel</Button>
                    <Button
                        onClick={() => { handleUpdateStatus(candidate?.candidate_id, status, comments); setOpen(false); setComments(undefined) }}
                    >
                        Add comment
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default AddCommentModal