import actions from "./actions";

const initialState = {
globalStats: {
    data: {},
    loading: false,
    error: null,
},
statsByJob: {
    data: [],
    loading: false,
    error: null,
},
statsByStatus: {
    data: [],
    loading: false,
    error: null,
},
statsByLevel: {
    data: [],
    loading: false,
    error: null,
},
filters: {
  jobs: [],
  status: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  level: ['Junior (0-2 years experience)', 'Mid senior (3-5 years experience)', 'Senior (>5 years experience)' ],
},
  loading: false,
  error: null,
};

export default function kpiReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
