const actions = {
    SET_STATE: "kpi/SET_STATE",
    RESET_STATE: "kpi/RESET_STATE",
    GET_GLOBAL_STATS: "kpi/GET_GLOBAL_STATS",
    GET_STATS_BY_JOB: "kpi/GET_STATS_BY_JOB",
    GET_STATS_BY_STATUS: "kpi/GET_STATS_BY_STATUS",
    GET_STATS_BY_LEVEL: "kpi/GET_STATS_BY_LEVEL",
    GET_FIELDS: "kpi/GET_FIELDS",
  };
  
  export default actions;
  