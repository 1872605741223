import actions from "./actions";

const initialState = {
    open: false,
    message: "",
    severity: "success",
};

export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
            return { ...state, ...action.payload };
        case actions.RESET_STATE:
            return { ...initialState };
        default:
            return state;
    }
}
