import actions from "./actions";
import {
    takeLatest,
    put,
    all,
} from "redux-saga/effects";

export function* fireNotificationCall(_, { payload }) {
    const { message, severity } = payload;

    yield put({
        type: actions.SET_STATE,
        payload: {
            open: true,
            message,
            severity
        },
    });

}
//Watcher SAGA:
export default function* rootSaga() {
    yield all([takeLatest(actions.FIRE_NOTIFICATION, fireNotificationCall)]);

}