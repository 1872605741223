import React from "react";
import "./styles.css";
import NotificationIcon from "../../assets/icons/notification.svg";
import SettingsIcon from "../../assets/icons/settings.svg";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import { blue, purple } from "@material-ui/core/colors";
import {Link} from "react-router-dom";
import routesList from "../../routes/routesList"
import { logout } from "../../utils/auth";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Logout from '@mui/icons-material/Logout';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

function DashboardHeader() {
  const dispatch = useDispatch();
  const { lastname, firstname, roles, user_id, loading } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // useEffect(() => {
  //   if (localStorage.getItem("user")) {
  //     dispatch({
  //       type: actions.SET_STATE,
  //       payload: JSON.parse(localStorage.getItem("user")),
  //     });
  //   }
  // }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className="dashboard-header-container">
      <div className="dashboard-header-left">
        {loading ? (
          <span>LOADING...</span>
        ) : (
          <span>
            {firstname} {lastname}
          </span>
        )}
      </div>
      <div className="dashboard-header-right">
        <img
          src={NotificationIcon}
          alt="notification-icon"
          className="dashboard-header-icon"
        />
        <img
          src={SettingsIcon}
          alt="settings-icon"
          className="dashboard-header-icon"
        />

        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              className="dashboard-header-avatar"
              style={{
                fontSize: "1rem",
                backgroundColor: roles?.includes("root")
                  ? purple[600]
                  : blue[400],
              }}
              alt="Avatar"
            >
              {`${firstname[0]?.toUpperCase()}.${lastname[0]?.toUpperCase()}`}
            </Avatar>
          </IconButton>
        </Tooltip>

        {/*
        <Avatar
          style={{ fontSize: "1rem", backgroundColor: blue[700] }}
          className="dashboard-header-avatar"
          alt="avatar"
        >
          {`${firstname[0]?.toUpperCase()}.${lastname[0]?.toUpperCase()}`}
        </Avatar> 
        */}
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link
          to={routesList.profile}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar />
            Profile
          </MenuItem>
        </Link>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}

export default DashboardHeader;
