import React from "react";
import {useSelector} from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import {useIsLogin} from "../utils/auth";


//Adding Allowed Roles which is an array containing the permitted roles to the Outlet (containing the page)

const PrivateRoutes = ({ allowedRoles }) => {
  const location = useLocation();
  const isLogin = useIsLogin();
  
  return allowedRoles?.includes( typeof isLogin == "object" ? isLogin.roles[0] : null ) ? (
    <Outlet />
  ) : isLogin?.user ? (
    <Navigate to="/401" state={{from: location}} replace />
  ) : (
    <Navigate to="/login" state={{from: location}} replace/>
  );
};

export default PrivateRoutes;
