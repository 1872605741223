import React from 'react';
import {useSelector} from 'react-redux';
import CreateUserForm from "../../components/CreateUserForm";
import DashboardHeader from '../../components/DashboardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Profile() {
  const {loading} = useSelector((state) => state.auth);

  return (
    <div className="dashboard-content">
      <DashboardHeader />
      {loading ? (
        <Box style={{ display: 'flex', width:"100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box> 
       ) : (
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Create Agent</h2>

        </div>
    <CreateUserForm/>
    </div>
       )}
    </div>
  )
}

export default Profile;